<template>
  <div class="sliderContainer px-2">
    <vue-slider v-model="value" :enable-cross="false" :max="max"></vue-slider>
    <div class="text-center flex">
      <div class="flex-1 border mr-1">
        <div class="text-gray-700 px-1">
          <small>Minimum</small>
        </div>
        <div class="font-bold">{{ value[0] }}{{ unit }}</div>
      </div>
      <div class="flex-1 border ml-1">
        <div class="text-gray-700 px-1">
          <small>Maximum</small>
        </div>
        <div class="font-bold">{{ value[1] }}{{ unit }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
export default {
  name: 'RangeSlider',
  components: {
    VueSlider,
  },
  props: {
    range: Array,
    unit: String,
    max: {
      type: Number,
      default: 100,
      required: false,
    },
  },
  data() {
    return {
      value: [0, 0],
      changeTimeout: null,
    }
  },
  watch: {
    value: {
      handler(val) {
        clearTimeout(this.changeTimeout)
        this.changeTimeout = setTimeout(() => {
          this.$emit('change', val)
        }, 600)
      },
      deep: true,
    },
  },
  mounted() {
    this.value = this.range
  },
}
</script>
<style>
.vue-slider-process {
  background-color: black !important;
}
.vue-slider-dot-handle {
  border-color: black !important;
}
.vue-slider:hover .vue-slider-process {
  border-color: rgba(0, 0, 0, 0.808) !important;
}
.vue-slider:hover .vue-slider-dot-handle {
  border-color: rgba(0, 0, 0, 0.808) !important;
}
.sliderContainer {
  width: auto;
}
@media screen and (min-width: 640px) {
  .sliderContainer {
    max-width: 231px;
  }
}
</style>
